import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

import { TypesenseConfig } from "../../../utils/third-party/typesense";

export const typesenseAdapter = new TypesenseInstantsearchAdapter({
    server: TypesenseConfig,
    additionalSearchParameters: {
        query_by: "title,description,slug",
        num_typos: 3,
        typo_tokens_threshold: 3,
    },
});
